import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData } from "../../../api/api";
import ProgramCard from "../../../components/cards/program-card/program-card.component";
import { Row, Col, Table } from "react-bootstrap";
import { Facebook } from "react-content-loader";
import ProfileModal from "../../../components/modal/profile-modal/profile-modal.component";
import SittingModal from "../../../components/modal/sitting-modal/sitting-modal.component";
import moment from "moment";
import useAccessLevels from "../../../hooks/useAccessLevels";

const SittingsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [, setCurrentProgram] = useGlobal("currentProgram");
  const [programs, setPrograms] = useGlobal("programs");
  const [state, setState] = useState({
    query: "",
    status: "",
    loading: true,
    openModal: false,
    activeSitting: null,
  });

  const [ended,setEnded]= useState(false)
  const { data, error, mutate, isValdating } = useSWR(
    `tuition/sitting?query=${state?.query}&active=${ended? "-1":"1"}`
  );

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Sittings");

  return (
    <div>
      <SittingModal
        open={state.openModal}
        sitting={state?.activeSitting}
        currentUser={currentUser}
        onUpdated={() => {
          mutate();
        }}
        onHide={() => {
          setState({ ...state, openModal: false, activeSitting: null });
        }}
      />

      <PageLayout
        activeLink="sittings"
        parentMenu="sittings"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24">
            {" "}
            Sittings
            {access?.write && (
              <span
                className="text-danger mx-3 pointer text-18 font-500"
                onClick={() => {
                  setState({ ...state, openModal: true });
                }}
              >
                Add new Sitting
              </span>
            )}
          </div>
          <label htmlFor="ended">
            Ended Only{" "}
            <input
              id="ended"
              type="checkbox"
              checked={ended}
              onChange={(e) => {
                setEnded(e.target.checked);
              }}
            />
          </label>
          <LoaderPane
            loading={isValdating || (!data && !error)}
            noRecords={!isValdating && data?.sittings?.length === 0}
            error={error?.message}
          />

          {data && (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Description</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Program</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.sittings?.map((s, i) => (
                    <tr key={i}>
                      <td>{s.id}</td>
                      <td>{s?.description || "N/A"}</td>
                      <td>{new Date(s.startAt).toDateString()}</td>
                      <td>{new Date(s.endAt).toDateString()}</td>
                      <td>
                        {s.programID === 1
                          ? "ICAG"
                          : s.programID === 2
                          ? "CITG"
                          : s.programID === 3
                          ? "Executive Training"
                          : "CIMA"}
                      </td>

                      <td>
                        {access?.write && (
                          <span
                            className="text-danger pointer px-1 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                openModal: true,
                                activeSitting: s,
                              });
                            }}
                          >
                            Edit
                          </span>
                        )}

                        <span
                          className="text-danger font-500 pointer"
                          onClick={() => {
                            props.history.push(
                              `/admin/sittings/${s.id}?name=${moment(
                                s.startAt
                              ).format("MMMM, YYYY")} - ${moment(
                                s.endAt
                              ).format("MMMM, YYYY")}&program=${
                                s.programID === 1
                                  ? "ICAG"
                                  : s.programID === 2
                                  ? "CITG"
                                  : s.programID === 3
                                  ? "Executive Training"
                                  : "CIMA"
                              }`
                            );
                          }}
                        >
                          {" "}
                          View{" "}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(SittingsPage);
